import { Link } from "gatsby"
import React from "react"
import "./header.scss"
const Header = () => (
  <header
    
  >
    <nav>
    <div
    className= "home-link-container"
    >
      <h1 className="home-link-title">
        <Link
          to="/"
         
        >
          EPL
        </Link>
      </h1>
    </div>
    <div
    className="navbar-links-container"
    >
     <ul className="top-menu-list">
        <li><Link className="top-nav-link"
          to="/"
        >
          CHI SONO
        </Link></li>
        <li><Link className="top-nav-link"
          to="/cosa/"
        >
          COSA FACCIO
        </Link></li>
        <li><Link className="top-nav-link"
          to="/contatti/"
          
        >
          CONTATTI
        </Link></li>
    </ul>
    </div>
    </nav>
  </header>
)



export default Header
