
import React from "react"
import { Link } from "gatsby"
import "./footer.scss"
const Footer = () => (
<footer>
<p className="footer-text">© 2020 Eugenio Pilastro Luca - P.IVA 02658680992 - <Link to="/privacy-policy/">Privacy policy</Link></p>
</footer>
)



export default Footer

