
import { Link } from "gatsby"
import React from "react"
import "./nav.scss"
const Nav = (props) => (
    <>
    <div className="nav-button-container-cutter" >
<div className="nav-button-container">
    <button className={props.isMenuOpen ? "nav-button hamburger hamburger--spin js-hamburger is-active" : "nav-button hamburger hamburger--spin js-hamburger" }
     onClick={props.handleNavButtonClick}
     aria-label={props.isMenuOpen ? "chiudi il menu" : "apri il menu"}
    >
        
        <span className="hamburger-box" >
          <span className="hamburger-inner" ></span>
        </span>
        
    </button>
    </div></div>
<nav className={props.isMenuOpen ? 'menu menu-open' : 'menu menu-hidden'}>
    <ul className="menu-list">
        <li><Link className="nav-link"
          to="/"
        >
          CHI
        </Link></li>
        <li><Link className="nav-link"
          to="/cosa/"
        >
          COSA
        </Link></li>
        <li><Link className="nav-link"
          to="/contatti/"
          
        >
          CONTATTI
        </Link></li>
    </ul>
</nav>
</>
)



export default Nav