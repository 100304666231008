/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import Nav from "./nav"
import "./layout.scss"

const Layout = ({ children }) => {
  
  const [isMenuOpen, setMenuState] = useState(false);
function handleNavButtonClick() {
 console.log('button click');
 setMenuState(!isMenuOpen);
}

  return (
    <>
      <Header/>
        {children}
        <Footer/>
        <Nav isMenuOpen={isMenuOpen} handleNavButtonClick={handleNavButtonClick}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
